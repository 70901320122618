import React from 'react'

function ColumnSuksesAndAbsen() {
    return (
        <>
            <th className='th-name'>Nama</th>
            <th className='th-niy'>NIY</th>
            <th className='th-jabatan'>Jabatan</th>
            <th className='th-waktu'>Presensi</th>
            <th className='th-action'>Action</th>
        </>
    )
}

export default ColumnSuksesAndAbsen