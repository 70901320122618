import React from 'react'

function ColumnMasukAndKeluar() {
    return (
        <>
            <th className='th-name'>Nama</th>
            <th className='th-niy'>NIY</th>
            <th className='th-jabatan'>Jabatan</th>
            <th className='th-tanggal'>Tanggal</th>
            <th className='th-waktu'>Waktu</th>
            <th className='th-action'>Action</th>
        </>
    )
}

export default ColumnMasukAndKeluar