import React from 'react'
import { useState } from 'react'
import Checkbox from '../../components/kalender/Checkbox'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { assignKategori, toggleCheck, detailKategori, getKaryawanPengaturan, getKategoriPengaturan, searchKaryawan, setCurrentKategori, setKategoriId, unassignKategori } from '../../features/pengaturanSlice'
import { Route, Routes, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import DisplayKategoriList from '../../components/DisplayKategoriList'
import imgErrorValidation from '../../utils/imgErrorValidation'
import LoadingFullscreen from '../../components/LoadingFullscreen'
import Skeleton from 'react-loading-skeleton'
import Button from '../../components/Button'

function EditKategoriKaryawan() {
    const [current, setCurrent] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [value, setValue] = useState('')

    const dispatch = useDispatch()
    const {
        listKategori,
        loadingKategori,
        listKaryawan,
        loadingAssign,
        currentKategori,
        listKaryawanNotFinal,
        listSearchKaryawan,
        kategoriId,
    } = useSelector((state) => state.pengaturan)
    const { kategori, id } = useParams()
    const [kategoriIsUpdated, setKategoriIsUpdated] = useState(false)
    const [listUnassignKaryawan, setListUnassignKaryawan] = useState([])

    useEffect(() => {
        dispatch(getKategoriPengaturan())
        dispatch(getKaryawanPengaturan({ route: 'setting' }))
        setCurrent(id)
    }, [id])

    useEffect(() => {
        dispatch(setCurrentKategori(kategori))
        dispatch(setKategoriId(id))
    }, [kategori, id])

    useEffect(() => {
        if (!loadingKategori) {
            setKategoriIsUpdated(true)
        }
    }, [loadingKategori])

    useEffect(() => {
        if (kategoriIsUpdated) {
            dispatch(detailKategori(id))
        }
    }, [kategoriIsUpdated, id])

    function handleShowModal() {
        setShowModal(prevShowModal => !prevShowModal)
    }

    function handleChange(e) {
        const inputValue = e.target.value;
        setValue(inputValue);
        dispatch(searchKaryawan({ name: 'listSearchKaryawan', value: inputValue }))
    }

    function handleChangeCheckbox(name, index) {
        if (name === 'listKaryawan') {
            dispatch(toggleCheck({ name: 'listKaryawan', index }));
        } else {
            dispatch(toggleCheck({ name: 'listKaryawanNotFinal', index }));
            dispatch(toggleCheck({ name: 'listSearchKaryawan', index }));
        }
    }

    function handleAssignKategori() {
        const selectedListKaryawan = listSearchKaryawan.filter(item => item.isChecked).concat(listKaryawan)

        dispatch(assignKategori({
            kategori_id: id,
            listKaryawan: selectedListKaryawan
        }))
            .then((res) => {
                if (res.meta.requestStatus === "fulfilled") {
                    dispatch(detailKategori(id))
                    dispatch(getKategoriPengaturan())
                    dispatch(getKaryawanPengaturan({ route: 'setting' }))
                    handleShowModal()
                }
            })
    }

    const handleDelete = () => {
        dispatch(unassignKategori({
            kategori_id: kategoriId,
            listKaryawan: listUnassignKaryawan
        }))
            .then((res) => {
                if (res.meta.requestStatus === "fulfilled") {
                    dispatch(detailKategori(id))
                    dispatch(getKategoriPengaturan())
                    dispatch(getKaryawanPengaturan({ route: 'setting' }))
                    setShowAlert(false)
                }
            })
    };

    useEffect(() => {
        setListUnassignKaryawan(listKaryawan.filter(item => !item.isChecked))
    }, [listKaryawan])

    return (
        <div className='detail-kategori-karyawan'>
            <Routes>
                <Route path='/:kategori/:id' element={<EditKategoriKaryawan />} />
            </Routes>
            <div className='wrapper-kategori'>
                <h1>Kategori Karyawan</h1>
                <div className='container-list-kategori'>
                    {listKategori.map((data, index) => (
                        <Link to={`/pengaturan/kategori-karyawan/${data.kategori}/${data.id}`} key={index}>
                            <div className={`${data.id == current ? 'active' : ''} kategori`} onClick={() => setCurrent(data.id)}>
                                <p>{data.kategori}</p>
                                <span>{data.jumlah + " Guru"}</span>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>

            <div className='wrapper-edit-kategori'>
                <div className='wrapper-text'>
                    <h1>Edit Kategori Karyawan</h1>
                    <h2 onClick={() => handleShowModal()}>Tambahkan Karyawan</h2>
                </div>
                <div className='wrapper-column'>
                    {loadingKategori ?
                        Array.from({ length: 5 }, (_, index) => (
                            <div className='container__list__skeleton' key={index}>
                                <div className='left__content'>
                                    <Skeleton width={35} height={35} circle={true} />
                                    <div>
                                        <Skeleton width={100} height={20} />
                                        <Skeleton width={70} height={14} />
                                    </div>
                                </div>
                                <Skeleton width={24} height={24} circle={true} />
                            </div>
                        ))
                        : listKaryawan.map((item, index) => {
                            return (
                                <div className='wrapper-list' key={index}>
                                    <div className='list-content-left'>
                                        <img src={item.link_foto} onError={imgErrorValidation} alt="" />
                                        <div>
                                            <p>{item.nama}</p>
                                            <span>
                                                <DisplayKategoriList list={item.ktgkaryawan} />
                                            </span>
                                        </div>
                                    </div>
                                    <input
                                        type="checkbox"
                                        name='listKaryawan'
                                        checked={item.isChecked}
                                        value={item.isChecked}
                                        onChange={() => handleChangeCheckbox('listKaryawan', item.id)}
                                    />
                                </div>
                            )
                        })
                    }

                    {listKaryawan.length === 0 && !loadingKategori && <p className='empty'>Tidak ada guru di kategori {currentKategori}</p>}
                </div>

                {listUnassignKaryawan.length > 0 &&
                    <div className='total-selected-user'>
                        <p>Jumlah {listUnassignKaryawan.length}</p>
                        <Button
                            text={'Hapus'}
                            onClick={() => setShowAlert(true)}
                        />
                    </div>
                }
            </div>

            {showAlert &&
                <div className='bg-modal'>
                    <div className='alert-modal'>
                        <h1>Edit Kategori Karyawan</h1>
                        <p>Kamu yakin hapus {listUnassignKaryawan.length} karyawan dari kategori?</p>
                        <div>
                            <button onClick={() => setShowAlert(false)}>Tidak</button>
                            <button onClick={handleDelete}>Iya</button>
                        </div>
                    </div>
                </div>
            }

            {showModal &&
                <div className='bg-modal'>
                    <div className='modal-tambah-anggota'>
                        <h1>Tambah Anggota</h1>
                        <h3>Guru Anggota {currentKategori}</h3>
                        <div className='padding'>
                            <input
                                type="text"
                                placeholder='Cari Anggota'
                                value={value}
                                onChange={handleChange}
                            />
                            <h4>Anggota</h4>
                            <div className='wrapper-list'>
                                {listKaryawanNotFinal.length === 0 ? <p className='empty'>Data tidak ditemukan</p>
                                    : loadingKategori
                                        ? Array.from({ length: 15 }, (_, index) => (
                                            <div className='skeleton-tambah-anggota'>
                                                <Skeleton width={20} height={20} />
                                                <div>
                                                    <Skeleton width={25} height={25} circle={true} />
                                                    <Skeleton width={50} height={20} />
                                                </div>
                                            </div>
                                        ))
                                        : listKaryawanNotFinal.map((item, index) => {
                                            const isCurrentId = item.ktgkaryawan.some((data) => data.id == kategoriId);

                                            if (!isCurrentId) {
                                                return (
                                                    <div className='container-anggota' key={index}>
                                                        <Checkbox
                                                            name='listKaryawanNotFinal'
                                                            control={index}
                                                            onChange={() => handleChangeCheckbox('listKaryawanNotFinal', item.id)}
                                                            checked={item.isChecked}
                                                        />
                                                        <img src={item.link_foto} onError={imgErrorValidation} alt='' />
                                                        <label htmlFor={index}>{item.nama}</label>
                                                    </div>
                                                );
                                            }
                                        })

                                }
                            </div>
                        </div>
                        <div className='wrappper-btn'>
                            <button onClick={() => handleShowModal()}>Cancel</button>
                            <button onClick={handleAssignKategori}>Tambah</button>
                        </div>
                    </div>
                </div>
            }
            <LoadingFullscreen loading={loadingAssign} />
        </div>
    )
}

export default EditKategoriKaryawan